<!--
 * @Description: 路内停车管理系统 运营管理 黑白名单 白名单 whiteList
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-06 14:47:20
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainBody">
    <!--上半部查询-->
    <el-row class="topSelectLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row>
          <el-col :span="6">
            <el-form-item label='车牌号'
                          label-width="90px">
              <el-input placeholder="请输入"
                        v-model="searchForm.plateNumber">
                <i class="el-icon-search el-input__icon"
                   slot="prefix">
                </i>
              </el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="queryButton">查 询</el-button>
          <el-button type="info"
                     @click="resetForm"
                     style="margin-left:17px;">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>白名单车辆管理</span>
        <span class="tableTitleButton topButton">
          <el-button type="success"
                    v-show="insert_user"
                     @click="addTemporaryVehicles">新 增</el-button>
          <el-button type="info"
                     style="margin-left:17px"
                     v-show="import_dialog"
                     @click="importDialog = true">导 入</el-button>
          <el-button type="info"
                     style="margin-left:17px"
                     @click="exportFile">导 出</el-button>
          <el-button type="danger"
                     style="margin-left:17px"
                     v-show="mass_deletion"
                     @click="massDeletion">批量删除</el-button>
        </span>
      </el-row>
      <el-row class="tableContent">
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  @getList="getList"
                  :paginationConfig='paginationConfig'
                  :tableFun="tableFun">
          <template slot="isFirst">
            <el-table-column type="selection"></el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="白名单状态"
                             prop='blackWhiteListStatus'>
              <template slot-scope="scope">
                <span :class="blackWhiteListStatus(scope.row.blackWhiteListStatus)">
                  {{scope.row.blackWhiteListStatus == '1'?'生效中':'已失效'}}</span>
              </template>
            </el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作"
                             class-name='class-name'>
              <template slot-scope="scope">
                <el-row>
                  <el-col :span="8">
                    <el-button @click="outOfCommission(scope.row)"
                               type="text"
                               size="medium">
                      {{scope.row.blackWhiteListStatus === 1?'停用':'启用'}}
                    </el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button @click="handleOrderUpdate(scope.row)"
                               type="text"
                               v-show="click_update"
                               size="medium">编辑</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button type="text"
                               size="medium"
                               v-show="click_delete"
                               @click="handleOrderDelete(scope.row)">删除</el-button>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </template>
        </dt-table>
        <!-- 新增  修改-->
        <el-dialog :title="this.dialogTitle"
                   :visible.sync="dialogVisible"
                   :close-on-click-modal="false"
                   append-to-body>
          <el-form label-width="110px"
                   :model="addForm"
                   ref="addForm"
                   :rules="rules"
                   clearValidate
                   :inline="true">
            <el-row justify="space-around"
                    style="margin-bottom:20px;">
              <el-col :span="12">
                <el-form-item label="车牌颜色"
                              prop="numberPlateColorCode">
                  <el-select clearable
                             v-model="addForm.numberPlateColorCode"
                             placeholder="请选择">
                    <el-option v-for="(item, index) in colorList"
                               :key="index"
                               :label="item.name"
                               :value="item.code"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="车牌号"
                              prop="plateNumber">
                  <el-input v-model="addForm.plateNumber"
                            placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row justify="space-around"
                    style="margin-bottom:20px;">
              <el-col :span="12">
                <el-form-item label="生效时间"
                              prop="effectiveTime">
                  <el-date-picker v-model="addForm.effectiveTime"
                                  type="date"
                                  value-format="yyyy-MM-dd"
                                  placeholder="选择日期时间">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="失效时间"
                              prop="expirationTime">
                  <el-date-picker v-model="addForm.expirationTime"
                                  type="date"
                                  value-format="yyyy-MM-dd"
                                  placeholder="选择日期时间">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row justify="space-around"
                    style="margin-bottom:20px;">
              <el-col :span="12">
                <el-form-item label="车主姓名"
                              prop="carOwnerName">
                  <el-input v-model="addForm.carOwnerName"
                            placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="手机号"
                              prop="phoneNumber">
                  <el-input v-model="addForm.phoneNumber"
                            placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row justify="space-around"
                    style="margin-bottom:20px;">
              <el-col :span="12">
                <el-form-item label="车主联系地址"
                              prop="carOwnerContactAddress">
                  <el-input v-model="addForm.carOwnerContactAddress"
                            placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="备注"
                              prop="remark">
                  <el-input v-model="addForm.remark"
                            placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-row class="dialog-button">
            <el-button @click="dialogVisible = false"
                       type="info">取 消</el-button>
            <el-button type="primary"
                       @click="handleClose('addForm')"
                       style="margin-right:10px">确 定</el-button>
          </el-row>
        </el-dialog>
      </el-row>
    </el-row>
    <!-- 导入 -->
    <el-dialog title="导入数据"
               :visible.sync="importDialog"
               :close-on-click-modal="false"
               append-to-body>
      <el-upload ref="upload"
                 :auto-upload="false"
                 :file-list="fileList"
                 :http-request="myUpload"
                 :limit="1"
                 :on-change="addFile"
                 :on-exceed="handleExceed"
                 :show-file-list="true"
                 accept=".xls, .xlsx"
                 action=""
                 style="text-align: center;">
        <el-row style="display:flex">
          <el-button size="small"
                     style="margin-left: 15px"
                     type="primary"
                     @click="queryTemplateDl">
            模板下载
          </el-button>
          <el-button size="small"
                     type="primary">
            选择文件
          </el-button>
        </el-row>
        <div slot="tip"
             class="el-upload__tip"
             style="font-size:10px;color:#ff0000;margin-top:30px;">
          请先下载模板！
        </div>
      </el-upload>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     class="popBtn"
                     @click="confimImportBatch">导 入</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'

export default {
  components: { dtTable, },
  data () {
    return {
      tableFun: { 'selection-change': this.handleChange, },
      tableConfig: { border: true },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      listMap: { pageNum: 1, pageSize: 15, },
      tableList: { list: [] },
      searchForm: {}, // 搜索内容
      pageNum: 1, // 分页
      pageSize: 15,
      tableColumnList: [
        {
          prop: 'plateNumber',
          label: '车牌号',
        },
        {
          prop: 'numberPlateColor',
          label: '车牌颜色',
        },
        {
          prop: 'effectiveTime',
          label: '生效时间',
        },
        {
          prop: 'expirationTime',
          label: '失效时间',
        },
        {
          prop: 'remark',
          label: '备注',
        },
      ],
      addForm: {},  //新增表单
      colorList: [],//车牌颜色下拉框
      dialogTitle: '',//弹出框标题
      dialogVisible: false,//弹出框是否显示
      massDeleteList: [],//多选框列表
      //校验
      rules: {
        plateNumber: [
          {
            required: true,
            message: '车牌号不能为空',
            trigger: 'blur'
          },
          {
            pattern: /^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1})$/,
            message: '请输入正确的车牌号',
            trigger: 'blur'
          }
        ],
        //手机号校验
        phoneNumber: [{ pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的手机号', trigger: 'blur' }],
        numberPlateColorCode: [{ required: true, message: '车牌颜色不能为空', trigger: 'blur' }],
        effectiveTime: [{ required: true, message: '生效时间不能为空', trigger: 'blur' }],
        expirationTime: [{ required: true, message: '失效时间不能为空', trigger: 'blur' }],
      },

      importDialog: false, // 导入弹窗
      fileList: [],
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    //获取菜单按钮权限
    this.buttonList = localStorage.getItem('buttonListByUser')
    this.buttonShow();

    this.getQueryDict()
    this.queryTableList()
    this.getColorDict()
  },
  //方法集合
  methods: {
     //菜单按钮展示权限
     buttonShow () {
      const buttonMenuName = JSON.parse(this.buttonList)
      const menuNames = [];
      for (let i = 0; i < Object.keys(buttonMenuName).length; i++) {
        const menuName = buttonMenuName[i].menuName;
        menuNames.push(menuName);
        if (menuNames[i] === '新增') {
          this.insert_user = true
        }
        else if (menuNames[i] === '修改') {
          this.click_update = true
        }
        else if (menuNames[i] === '删除') {
          this.click_delete = true
        }
        else if (menuNames[i] === '导入') {
          this.import_dialog = true
        }
        else if (menuNames[i] === '批量删除') {
          this.mass_deletion = true
        }
      }
    },
    queryTemplateDl () {
      let arr = new Array()
      arr[0] = "parkId"
      arr[1] = "joinReason"
      this.$axios.get(this.$downloadBaseUrl + "BlackWhiteListController/downloadTemplateWhite?param=" + encodeURI(JSON.stringify(arr)), {
        responseType: "blob",
        headers: {
          'userToken': localStorage.getItem("userToken")
        },
      }).then(res => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "白名单车辆模板.xlsx";
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url);
      })
    },
    // 上传文件
    addFile (file) {
      if (!(file.name.endsWith("xls") || file.name.endsWith("xlsx"))) {
        this.fileList = [];
        this.$message.warning(`文件格式有误,请选择正确的Excel文件`);
      }
    },
    // 限制文件
    handleExceed () {
      this.$message.warning(`对不起,一次仅限上传一个文件！`);
    },
    // 上传文件  
    myUpload (content) {
      let _self = this;
      var FileController = "";
      FileController = this.$downloadBaseUrl + "BlackWhiteListController/uploadWhite";
      var form = new FormData();
      form.append("file", content.file);
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = onloadFun;
      xhr.open("POST", FileController, true);
      xhr.send(form);
      function onloadFun () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          var resText = JSON.parse(xhr.responseText);
          if (resText.resultCode === "2000") {
            _self.fileList = [];
            _self.$message({ message: "导入成功", type: "success" });
            _self.importDialog = false;
            _self.queryTableList();
          } else {
            _self.$message.error({ message: "对不起！文件上传失败", type: "error" });
          }
        }
      }
    },
    // 确认导入按钮
    confimImportBatch () {
      this.$refs.upload.submit();
      this.importDialog = false;
    },
    // 导出
    exportFile () {
      this.$axios.get(this.$downloadBaseUrl + "BlackWhiteListController/downloadInWhite", {
        responseType: "blob",
        headers: {
          'userToken': localStorage.getItem("userToken")
        },
        params: { param: {} }
      }).then(res => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "白名单车辆.xlsx";
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url);
      })
    },
    //表格数据查询
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$onRoadwhiteList.queryWhiteListNoAuth(this.searchForm).then(response => {
        let list = response.resultEntity.list
        list.forEach(element => {
          if (element.effectiveTime) {
            element.effectiveTime = element.effectiveTime.slice(0, 10)
          }
          if (element.expirationTime) {
            element.expirationTime = element.expirationTime.slice(0, 10)
          }
        });
        this.tableList.list = list
        this.paginationConfig.total = response.resultEntity.total
      })
    },
    //条件查询
    queryButton () {
      this.pageNum = 1;
      this.listMap.pageNum = 1
      this.queryTableList()
    },
    //重置表单
    resetForm () {
      this.searchForm = {}
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryTableList()
    },
    //新增停车场按钮
    addTemporaryVehicles () {
      this.addForm = { listType: '0' }
      this.dialogTitle = '新增白名单'
      this.dialogVisible = true
      this.dialogType = 0
    },
    //弹窗确认按钮
    handleClose (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogType === 0) {
            this.add()
          } else if (this.dialogType === 1) {
            this.update()
          }
          this.dialogVisible = false
        } else {
          return false;
        }
      })
    },
    //解决手机号输入框输入又取消没值 后端无法接受问题
    cleanObj (obj) {
      console.log(obj);
      for (const key in obj) {
        console.log(obj);
        if (!obj[key]) {
          obj[key] = undefined
        }
      }
    },
    //新增列表
    add () {
      if (this.addForm.expirationTime < this.addForm.effectiveTime) {
        this.$message({ message: '失效时间应大于生效时间', type: 'error' });
      }
      else {
        this.$onRoadwhiteList.blackWhiteInsert(this.addForm).then((response) => {
          if (response.resultCode === '2000') {
            this.dialogVisible = false
            this.$message({ message: '新增成功', type: 'success' });
            this.queryTableList()
          }
        })
      }
    },
    //修改列表
    update () {
      this.addForm["blackWhiteListStatus"] = 1
      if (this.addForm.expirationTime < this.addForm.effectiveTime) {
        this.$message({ message: '失效时间应大于生效时间', type: 'error' });
      } else {
        this.$onRoadwhiteList.blackWhiteUpdate(this.addForm).then((response) => {
          if (response.resultCode === '2000') {
            this.$message({ message: '修改成功', type: 'success' });
            this.queryTableList()
            this.dialogVisible = false
            this.outOfCommission(this.addForm)
          }
        }).catch(err => {
          this.$message({ message: err.resultMsg, type: 'error' });
        })
      }
    },
    // 删除记录
    handleOrderDelete (row) {
      this.$confirm('确认删除该条记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let info = [{
          listId: row.listId,
        }]
        this.$onRoadwhiteList.blackWhiteDelete(info).then(response => {
          if (response.resultCode === '2000') {
            this.$message({ message: '刪除成功', type: 'success' });
            this.queryTableList()
          }
        })
      })
    },
    //表格多选按钮
    handleChange (val) {
      let arr = []
      val.forEach(item => {
        arr.push({
          listId: item.listId
        })
      })
      this.massDeleteList = arr
    },
    //批量删除
    massDeletion () {
      if (this.massDeleteList.length === 0) {
        this.$message({
          showClose: true,
          message: '至少勾选一条记录',
          type: 'error'
        });
      } else {
        this.$confirm('确认批量删除白名单车辆?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$onRoadwhiteList.blackWhiteDelete(this.massDeleteList).then(response => {
            if (response.resultCode === '2000') {
              this.$message({ message: '批量删除成功', type: 'success' });
              this.queryTableList()
            }
          })
        })
      }
    },
    //停用记录
    outOfCommission (row) {
      let info = {
        listId: row.listId,
      }
      if (row.blackWhiteListStatus === 1) {
        this.$onRoadwhiteList.disable(info).then(() => {
          this.$message({
            message: '停用成功',
            type: 'success'
          });
          this.queryTableList()
        })
      } else if (row.blackWhiteListStatus === 2) {
        // 启用
        this.$onRoadwhiteList.enable(info).then(() => {
          this.$message({
            message: '启用成功',
            type: 'success'
          });
          this.queryTableList()
        })
      }
    },
    // 操作-编辑
    handleOrderUpdate (row) {
      this.dialogType = 1
      this.dialogTitle = '白名单修改'
      this.dialogVisible = true
      this.addForm = this.$deepClone(row)
      this.addForm['listId'] = row.listId
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    //获取停车场名称
    getQueryDict () {
      let info = {
        columnName: ['park_id', 'park_name'], //有且只有两个 id放前面名称放后面
        tableName: 'tb_park',
      }
      this.$queryDict.queryDictWithAuth(info).then((response) => {
        let data = response.resultEntity
        this.parkNameList = data
      })
    },
    //获取车牌颜色
    getColorDict () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '204E657017184ADFAE6E39629B38DDC2',
          },
        ]
      }
      this.$queryDict.queryDict(info).then((response) => {
        let data = response.resultEntity
        this.colorList = data
      })
    },
    //状态颜色变化
    blackWhiteListStatus (param) {
      let res = "";
      if (param == '1') {
        res = "effective";
      } else if (param == '2') {
        res = "effectiveness";
      }
      return res;
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
.mainBody {
  height: 100%;
  .topSelectLine {
    @include background('blockBg');
    width: 100%;
    height: $topSelectHeight;
    padding-top: 24.3px;
    padding-left: 21px;
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    @include background('blockBg');
    width: 100%;
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .topButton {
        display: flex;
        padding-left: 95.5px;
      }
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
//生效字颜色
.effective {
  color: #bae8ff;
}
//失效字颜色
.effectiveness {
  color: #f56c6c;
}
.dialog-button .el-button {
  float: right;
}
</style>
